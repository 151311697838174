<template>
  <div :class="$style.component">
    <div ref="container" :class="$style.container" />
    <div :class="$style.wrapper" ref="outer">
      <!-- :style="wrapperStyle" -->
      <div :class="$style.inner" :style="scaled" ref="inner">
        <jw-player-video
          :media-id="item.mediaId"
          :autostart="autostart"
          :aspectRatio="aspectRatio"
          :active="active"
        />
      </div>

      <div v-if="item.caption" v-html="item.caption" :class="classes.caption" />
    </div>

    <transition name="t-section">
      <div v-if="showSectionTitle" :class="$style.section">
        <span v-html="item.sectionTitle" :class="$style.sectionTitle" />
      </div>
    </transition>
  </div>
</template>

<script>
import JwPlayerVideo from '@/components/jw-player-video'
import EventBus from '@/event-bus'

export default {
  components: { JwPlayerVideo },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    },
    autostart: {
      type: String,
      default: 'false'
    },
    aspectRatio: {
      type: String,
      default: 'auto'
    },
    isScaling: {
      type: Boolean,
      default: true
    },
    isCurrent: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      scale: 1,
      active: false,
      wrapperWidth: null
    }
  },
  computed: {
    classes() {
      return {
        caption: [
          this.$style.caption,
          this.isCurrent ? this.$style.isCurrent : ''
        ]
      }
    },
    scaled() {
      return this.isScaling
        ? { transform: 'translate(-50%, -50%) scale(' + this.scale + ')' }
        : ''
    },
    showSectionTitle() {
      return this.isCurrent && this.item.sectionTitle
    }
    // wrapperStyle() {
    //   return {
    //     width: this.wrapperWidth + 'px'
    //   }
    // }
  },
  methods: {
    contain() {
      const outer = this.$refs.outer
      const inner = this.$refs.inner
      if (outer === undefined || inner === undefined) return
      const scale = Math.min(
        outer.offsetWidth / inner.offsetWidth,
        outer.offsetHeight / inner.offsetHeight
      )
      this.scale = scale
    }

    // getWrapperWidth() {
    //   const container = this.$refs.container
    //   // get styles to subtract paddings, etc.
    //   // has to be done with getComputedStyle since there is no innerWidth on this element
    //   const containerStyles = window.getComputedStyle(container)
    //   const offsetTop = parseInt(containerStyles.paddingTop)
    //   const offsetBottom = parseInt(containerStyles.paddingBottom)
    //   const offsetLeft = parseInt(containerStyles.paddingLeft)
    //   const offsetRight = parseInt(containerStyles.paddingRight)

    //   // calculate actual container size without paddings, etc
    //   const containerHeight = container.offsetHeight - offsetTop - offsetBottom
    //   const containerWidth = container.offsetWidth - offsetLeft - offsetRight
    //   const cRatio = containerWidth / containerHeight
    //   // const videoRatio = this.item.source.width / this.item.source.height

    //   this.wrapperWidth =
    //     cRatio < videoRatio ? containerWidth : containerHeight * videoRatio
    // }
  },
  mounted() {
    // this.getWrapperWidth()

    if (this.isScaling) {
      EventBus.$on('video-ready', () => {
        this.contain()
      })
      window.addEventListener('resize', this.contain)
      // window.addEventListener('resize', this.getWrapperWidth)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.contain)
    // window.removeEventListener('resize', this.getWrapperWidth)
  },
  watch: {
    isCurrent() {
      this.isCurrent ? (this.active = true) : (this.active = false)
    },
    active() {
      if (this.isScaling && this.active) this.contain()
    }
  }
}
</script>

<style lang="scss" scoped>
.t-section-enter-active,
.t-section-leave-active {
  transition: opacity var(--long) linear;
}

// to avoid flashing when there are two identicla section titles
.t-section-leave-active {
  transition-delay: var(--xshort);
}

.t-section-enter,
.t-section-leave-to {
  opacity: 0;
}
</style>

<style lang="scss" module>
.component {
  position: relative;
  display: flex;
  flex-direction: column;
  // display: grid;
  // // based on: https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
  // // and: https://stackoverflow.com/questions/52861086/why-does-minmax0-1fr-work-for-long-elements-while-1fr-doesnt
  // grid-template-rows: repeat(2, minmax(0, 1fr)) auto;
  // grid-template-columns: repeat(2, 1fr);
  // // only works without overflow when using fr units
  // // see: https://css-tricks.com/introduction-fr-css-unit/
  // grid-gap: var(--item-image-margin);
  height: 100%;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 90vw;
  height: 100vh;
  padding: calc(var(--gutter) * 2) calc(var(--gutter) * 2)
    calc(var(--gutter) * 2) var(--gutter);

  @media (min-width: $medium) {
    max-width: 90vw;
    padding: calc(var(--gutter) * 5) calc(var(--gutter) * 5)
      calc(var(--gutter) * 5) calc(var(--gutter) * 5);
  }
}

.wrapper {
  position: relative;
  display: grid;
  // based on: https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
  // and: https://stackoverflow.com/questions/52861086/why-does-minmax0-1fr-work-for-long-elements-while-1fr-doesnt
  grid-template-rows: repeat(2, minmax(0, 1fr)) auto;
  grid-template-columns: repeat(2, 1fr);
  // only works without overflow when using fr units
  // see: https://css-tricks.com/introduction-fr-css-unit/
  grid-gap: var(--item-image-margin);
  width: 100%;
  height: 100%;
}

.outer {
  grid-row: 1 / 4;
  grid-column: 1 / 3;
}

.component,
.outer {
  position: relative;
  width: 100%;
  height: 100%;
}

.inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
}

.section {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 0 var(--gutter) var(--gutter) var(--gutter);
  // margin-bottom: -0.3em; // to align section title with lower border of cpation
}

.sectionTitle {
  @extend %ff-sans;
  @extend %fw-bold;
  @extend %fs-medium;

  text-transform: lowercase;
}

.caption {
  @extend %ff-sans;
  @extend %fw-bold;
  @extend %fs-caption;

  grid-row: 3;
  grid-column: 1 / 3;
  align-self: end;
  max-width: 90vw; // apply same max-width as image has
  opacity: 0;
  transition: opacity var(--long) linear;

  &.isCurrent {
    opacity: 1;
  }

  @media (min-width: $medium) {
    position: absolute;
    bottom: calc(var(--grid-gap) * -1);
    transform: translateY(100%);
  }
}
</style>
