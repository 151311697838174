<template>
  <div :class="classes.component">
    <div :class="classes.wrapper">
      <div :id="mediaId" v-if="mediaId" />
    </div>
  </div>
</template>

<script>
import { reduceRatio } from '@/mixins/reduce-ratio'
import EventBus from '@/event-bus'
import jwplayer from 'jwplayer'
import axios from 'axios'

export default {
  inheritAttrs: false,
  mixins: [reduceRatio],
  props: {
    mediaId: {
      type: String,
      required: true
    },
    controls: {
      type: Boolean,
      default: false
    },
    autostart: {
      type: String,
      // Allowed values: true, false, viewable
      // https://developer.jwplayer.com/jw-player/docs/javascript-api-reference/jw7/#jwplayersetconfig
      default: 'false'
    },
    mute: {
      type: Boolean,
      default: false
    },
    repeat: {
      type: Boolean,
      default: false
    },
    aspectRatio: {
      type: String,
      default: '16:9'
    },
    active: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      source: undefined,
      image: undefined,
      loading: false,
      error: null,
      player: undefined,
      ready: false,
      playing: false,
      complete: false
    }
  },
  computed: {
    classes() {
      return {
        component: [this.$style.component],
        wrapper: [
          this.$style.wrapper,
          this.ready ? 'is-ready' : '',
          this.playing ? 'is-playing' : ''
        ],
        video: [this.$style.video]
      }
    },
    autoRatio() {
      if (!this.source) return undefined
      const ratio = this.reduceRatio(this.source.width, this.source.height)
      return ratio
    }
  },
  methods: {
    setUpPlayer() {
      this.player = jwplayer(this.mediaId)
      this.player.setup({
        file: '//cdn.jwplayer.com/manifests/' + this.mediaId + '.m3u8',
        image: this.image,
        controls: this.controls,
        autostart: this.autostart,
        mute: this.mute,
        repeat: this.repeat,
        aspectratio: this.autoRatio ? this.autoRatio : this.aspectRatio
      })

      this.player.on('ready', () => {
        this.ready = true
        EventBus.$emit('video-ready')
        if (this.active) this.play()
      })

      this.player.on('viewable', () => {
        if (this.complete) this.play()
      })

      this.player.on('play', () => {
        this.playing = true
      })

      this.player.on('complete', () => {
        this.complete = true
      })

      this.player.on('setupError', message => {
        this.ready = false
        alert(message)
      })
    },
    play() {
      if (this.complete) this.complete = false
      this.player.play()
    },
    pause() {
      this.player.pause()
      this.playing = false
    },
    async fetchMedia() {
      this.source = {}
      this.image = undefined
      this.loading = true
      this.error = null
      axios
        .get(`//cdn.jwplayer.com/v2/media/` + this.mediaId)
        .then(response => {
          this.loading = false
          this.source = response.data.playlist[0].sources[1] // TODO: sources[1] only works as long as the response keeps the same structure
          this.image = response.data.playlist[0].image
          this.setUpPlayer() // TODO: would be cleaner to call via async/await
        })
        .catch(error => {
          alert(error)
        })
    }
  },
  mounted() {
    if (this.aspectRatio === 'auto') {
      this.fetchMedia()
    } else {
      this.setUpPlayer()
    }
  },
  watch: {
    active() {
      if (this.active) {
        this.play()
      } else {
        this.pause()
      }
    }
  },
  beforeDestroy() {
    // TODO: if remove() is called, then video does not fade out
    //this.player.remove()
  }
}
</script>

<style lang="scss" module>
.component {
  width: 100%;
}

.wrapper {
  opacity: 0;
  transition: opacity 350ms ease;

  &:global(.is-ready) {
    opacity: 1;
  }

  :global(.jw-wrapper) {
    background-color: transparent;
  }
}
</style>
